// N.B. this file can be merged into main entry point
//      when all sprockets library will be ported to webpack

import '../../common/expose_jquery';

import 'popper.js';

import 'jquery-ui/ui/core';

import 'jquery-ui/ui/effect';
import 'jquery-ui/ui/effects/effect-blind';
import 'jquery-ui/ui/effects/effect-bounce';
import 'jquery-ui/ui/effects/effect-clip';
import 'jquery-ui/ui/effects/effect-drop';
import 'jquery-ui/ui/effects/effect-explode';
import 'jquery-ui/ui/effects/effect-fade';
import 'jquery-ui/ui/effects/effect-fold';
import 'jquery-ui/ui/effects/effect-highlight';
import 'jquery-ui/ui/effects/effect-puff';
import 'jquery-ui/ui/effects/effect-pulsate';
import 'jquery-ui/ui/effects/effect-scale';
import 'jquery-ui/ui/effects/effect-shake';
import 'jquery-ui/ui/effects/effect-size';
import 'jquery-ui/ui/effects/effect-slide';
import 'jquery-ui/ui/effects/effect-transfer';

import 'jquery-ui/ui/widget';
import 'jquery-ui/ui/widgets/accordion';
import 'jquery-ui/ui/widgets/autocomplete';
import 'jquery-ui/ui/widgets/button';
import 'jquery-ui/ui/widgets/checkboxradio';
import 'jquery-ui/ui/widgets/controlgroup';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/widgets/dialog';
import 'jquery-ui/ui/widgets/draggable';
import 'jquery-ui/ui/widgets/droppable';
import 'jquery-ui/ui/widgets/menu';
import 'jquery-ui/ui/widgets/mouse';
import 'jquery-ui/ui/widgets/progressbar';
import 'jquery-ui/ui/widgets/resizable';
import 'jquery-ui/ui/widgets/selectable';
import 'jquery-ui/ui/widgets/selectmenu';
import 'jquery-ui/ui/widgets/slider';
import 'jquery-ui/ui/widgets/sortable';
import 'jquery-ui/ui/widgets/spinner';
import 'jquery-ui/ui/widgets/tabs';
import 'jquery-ui/ui/widgets/tooltip';

import 'bootstrap';
